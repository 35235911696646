import _ from "lodash"
import { Breadcrumb, Col, Row, Typography } from "antd"
import { graphql, Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import tw from "twin.macro"
import { MaxWidth, WhiteSpace } from "../components/common"
import Container from "../components/container"
import SEO from "../components/seo"
import YouTube from "react-youtube"

import {
  ProductCharacteristics,
  ProductDocuments,
  ProductTagline
} from "../components/product"
import ProductImages from "../components/product/product-images"
import { ShoppingCartOutlined } from "@ant-design/icons"
import { Button, Icon } from "@chakra-ui/react"
import { useWindowSize } from "../utils/windows-size"
import { getYoutubeVideoId } from "../utils/youtube"

const { Title, Text } = Typography

const DescriptionMarkdown = styled(ReactMarkdown)`
  ${tw`text-grey-darkest leading-snug w-full`}

  ul {
    list-style: inherit;
  }
`

const ProductTemplate = ({ data, intl }) => {
  const size = useWindowSize()

  const getBreadcrumCategoriesItems = () => {
    if (data.product.category) {
      return [
        <Breadcrumb.Item key={data.product.category.slug}>
          <Link to={`/products/${data.product.category.slug}`}>
            {data.product.category.title}
          </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key={data.product.slug}>
          <Link
            to={`/products/${data.product.category.slug}/${data.product.slug}`}
          >
            <Text strong>{data.product.title}</Text>
          </Link>
        </Breadcrumb.Item>
      ]
    } else if (data.product.subcategory) {
      return [
        <Breadcrumb.Item key={data.product.subcategory.category.slug}>
          <Link to={`/products/${data.product.subcategory.category.slug}`}>
            {data.product.subcategory.category.title}
          </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key={data.product.subcategory.category.slug}>
          <Link to={`/products/${data.product.subcategory.category.slug}`}>
            {data.product.subcategory.title}
          </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key={data.product.slug}>
          <Link
            to={`/products/${data.product.subcategory.category.slug}/${data.product.slug}`}
          >
            <Text strong>{data.product.title}</Text>
          </Link>
        </Breadcrumb.Item>
      ]
    } else {
      return []
    }
  }

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{intl.formatMessage({ id: "home" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="products">
      <Link to="/products">{intl.formatMessage({ id: "products" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="categories">
      <Link to="/products">{intl.formatMessage({ id: "categories" })}</Link>
    </Breadcrumb.Item>,
    ...getBreadcrumCategoriesItems()
  ]

  const opts = {
    height: size.width > 900 ? size.width * 0.3 : `${size.width * 0.5}`,
    width: size.width > 900 ? size.width * 0.5 : `${size.width * 0.8}`,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    }
  }

  const onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }

  return (
    <Container>
      <SEO title={data.product.title} />
      <WhiteSpace size={100} />
      <MaxWidth>
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        <WhiteSpace size={20} />
        <Row gutter={[24, 40]} justify="space-between">
          <Col xs={24} md={24} lg={10}>
            <ProductImages images={data.product.images} />
          </Col>
          <Col xs={24} md={24} lg={13}>
            <Row justify="start">
              <Text strong>
                {data.product.subcategory
                  ? data.product.subcategory.title
                  : data.product.category.title}
              </Text>
            </Row>
            <Row justify="start">
              <Title level={3}>{data.product.title}</Title>
            </Row>
            <WhiteSpace size={15} />
            <DescriptionMarkdown
              source={data.product.description.description}
              escapeHtml={false}
            />
            <WhiteSpace size={25} />

            {data.product.videoLink && (
              <>
                <WhiteSpace size={25} />
                <Row justify="center">
                  <YouTube
                    videoId={getYoutubeVideoId(data.product.videoLink)}
                    opts={opts}
                    onReady={onReady}
                  />
                </Row>
                <WhiteSpace size={25} />
              </>
            )}

            <WhiteSpace size={25} />
            <ProductTagline
              source={data.product.tagline.tagline}
              escapeHtml={false}
            />
            {data.product.storeLink && (
              <>
                <WhiteSpace size={25} />
                <Row justify="center">
                  <a href={data.product.storeLink} target="blank">
                    <Button
                      shape="round"
                      leftIcon={
                        <Icon
                          as={ShoppingCartOutlined}
                          color="gray.900"
                          mb={2}
                        />
                      }
                    >
                      <Text>Check in Store</Text>
                    </Button>
                  </a>
                </Row>
              </>
            )}

            <WhiteSpace size={25} />
            <Text strong>Chracteristics</Text>
            <ProductCharacteristics
              escapeHtml={false}
              source={data.product.characteristics.characteristics}
            />
            <WhiteSpace size={25} />
            <ProductDocuments documents={data.product.documents} />
          </Col>
        </Row>
      </MaxWidth>
    </Container>
  )
}

export const query = graphql`
  query($slug: String!, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      id
      new
      title
      slug
      storeLink
      videoLink
      description {
        description
      }
      tagline {
        tagline
      }
      characteristics {
        characteristics
      }
      documents {
        id
        title
        file {
          url
        }
      }
      images {
        id
        title
        sizes(quality: 100, maxWidth: 400) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      category {
        slug
        title
      }
      subcategory {
        title
        category {
          slug
          title
        }
      }
    }
  }
`

export default injectIntl(ProductTemplate)
